<template>
  <EditEntity
    :fields="fields"
    :save-url="saveUrl"
    :get-url="getUrl"
    :id="$route.params.id"
    @updateIsDataSaved="updateIsDataSaved"
  ></EditEntity>
</template>
<script>
import { defineComponent } from "vue";
import EditEntity from "@/components/EditEntity";

export default defineComponent({
  name: "admin-author",
  components: {
    EditEntity,
  },
  data: function () {
    return {
      saveUrl: "api/admin/authors/save",
      getUrl: "admin/authors/",
      isDataSaved: true,

      fields: [
        {
          name: "Name",
          value: "name",
        },
        {
          name: "Transliterated name (displays in URL!)",
          value: "transliteratedName",
        },
        {
          name: "Previous names",
          value: "previousNames",
        },
        {
          name: "Description",
          value: "description",
          type: "textarea",
        },
        {
          name: "Avatar Url",
          value: "avatar",
        },
        {
          name: "Or upload file",
          value: "avatar",
          type: "upload",
        },
        {
          name: "JSON Fields (TODO)",
          value: "fields",
          type: "textarea",
        },
      ],
    };
  },

  mounted() {
    window.addEventListener("beforeunload", this.beforeUnload);
  },

  beforeUnmount() {
    window.removeEventListener("beforeunload", this.beforeUnload); // Очистите обработчик, чтобы избежать утечек памяти
  },

  beforeRouteLeave(to, from, next) {
    if (!this.isDataSaved) {
      const answer = window.confirm(
          "У вас есть несохраненные изменения. Вы уверены, что хотите уйти?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  methods: {
    beforeUnload(event) {
      if (!this.isDataSaved) {
        const message =
            "У вас есть несохраненные изменения. Вы уверены, что хотите покинуть страницу?";
        event.returnValue = message; // Стандартное сообщение для разных браузеров
        return message; // Некоторые браузеры требуют возврата сообщения
      }
    },
    updateIsDataSaved: function (newValue) {
      this.isDataSaved = newValue;
    },
  },
});
</script>
